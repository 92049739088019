<template>
  <AwlCard v-bind="{...$attrs, ...$props}" class="awl-project-card">
    <template #title>
      <router-link :to="{ name: 'projects.show', params: { id: project.id } }" class="project-header">
        <div class="d-flex align-items-center mb-3">
            <img draggable="false" :src="project.image.thumbUrl" class="mr-3 rounded-sm card-image">
            <h4 :title="project.name" class="project-name">{{ project.name }}</h4>
        </div>
      </router-link>
    </template>

    <template #default>
      <slot name="footer">
        <div class="card-footer d-flex align-items-center justify-content-end primary-bg">
          <router-link :to="{ name: 'projects.edit', params: { id: project.id } }" class="btn btn-sm btn-secondary edit-btn">Modifica</router-link>
        </div>
      </slot>
    </template>
  </AwlCard>
</template>

<script>

import rolesMixin from '../../../mixins/roles';

export default {
  mixins: [rolesMixin],
  components: {
    AwlCard: () => import('./AwlCard'),
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
};

</script>

<style lang="scss">

.awl-project-card {
  overflow: hidden;
  border: 1px solid rgba(33, 37, 41, 0.075)!important;

  .card-image {
    height: 60px;
    object-fit: cover;
    width: 60px;
    box-shadow: 0px 0px 10px rgba(darken($primary, 30%), .1);
  }
  .project-header {
    color: $dark;
    &:hover {
      text-decoration: none !important;
    }
  }
  .project-name {
    max-width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
  }

  .awl-card--header {
    padding: 1rem !important;
    padding-bottom: 0 !important;
    .user-role {
      font-size: 16px;
    }
  }

  .awl-card--title {
      color: $dark !important;
      text-transform: initial !important;
      font-size: 1.5rem;
      line-height: 1.25rem;
      margin-bottom: 0;
    }

  .awl-card--content {
    padding: 0 1rem 1rem !important;
    background: rgba(33, 37, 41, 0.075);
  }

  hr {
    background: $light;
    border: none;
    height: 1px;
  }

  .card-footer {
    padding-top: 1rem;
  }

  .edit-btn {
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: .04rem;
    border: 0;
    font-size: 12px;
  }
}

</style>
